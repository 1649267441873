import Vue from 'vue'
// import 'element-ui/lib/theme-chalk/index.css'
import {
    Button,
    Container,
    Header,
    Main,
    Footer,
    Carousel,
    CarouselItem,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Input,
    Tabs,
    TabPane,
    Table,
    TableColumn
} from 'element-ui'


Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Table)
Vue.use(TableColumn)

