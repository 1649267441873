import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from 'axios'
// 引入flexible  自适应
import  'lib-flexible'

// 配置请求路径
// axios.defaults.baseURL="http://114.215.25.212/wz"
axios.defaults.baseURL="https://www.nxcyx.com/api/"
// axios.defaults.baseURL="http://localhost:8081"
// axios.defaults.baseURL="http://114.215.25.212"
Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
