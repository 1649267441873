<template>
	<div class="home_index_wrap">
		<el-carousel :autoplay="false"   indicator-position="outside" style="height:670px !important;width:100%  !important">
			<el-carousel-item   style="height:670px !important"  class="image" v-for="(item,index) in images" :key="index">
				<img @click="handleToSystem(index)" :src="item" width="100%" height="670px" alt="">
			</el-carousel-item>
		</el-carousel>
		<div class="home_section_one">
			<div>
				<img src="../../assets/图层 26.png" alt="">
				<h3>解决方案</h3>
				<p>面向工业的数字化、网络化、智能化；基于互联网+工业模式进行升级和布局</p>
			</div>
			<div>
				<img src="../../assets/图层 25.png" alt="">
				<h3>安全保障</h3>
				<p>技术管理、设备安全、控制安全、网络安全、平台安全和数据安全多层保障体系</p>
			</div>
			<div>
				<img src="../../assets/图层 24.png" alt="">
				<h3>服务团队</h3>
				<p>创始人及核心团队来自国内各大互联网公司、研发方面均有5年以上行业经验</p>
			</div>
			<div>
				<img src="../../assets/图层 22.png" alt="">
				<h3>核心服务</h3>
				<p>以软、硬件研发为核心，致力于智慧牧场、智慧工业等系统的研发及实施</p>
			</div>
		</div>
		<div class="home_section_two">
			<div style="margin-left: 4%">
				<h2>公司简介</h2>
<!--				<h2>About Us</h2>-->
			</div>
			<div class="section_two_content">
				<div class="content_left" v-html="information">
					{{information}}
				</div>
				<div class="content_right">
					<img src="../../assets/js.png" alt="">
				</div>
			</div>
		</div>
		<div class="home_section_three">
			<div class="three_item">
				<span>25</span>
				<span>知识产权</span>
			</div>
			<div class="three_item">
				<span>02</span>
				<span>发明专利</span>
			</div>
			<div class="three_item">
				<span>09</span>
				<span>实用新型专利</span>
			</div>
			<div class="three_item">
				<span>14</span>
				<span>软件著作产权</span>
			</div>
		</div>
		<div class="home_section_four">

			<div class="four_content">
				<div class="four_content_left">
					<el-button @click="handleChangeImgs('1')" type="primary">犊牛饲喂管理系统</el-button>
					<el-button @click="handleChangeImgs('2')" type="primary">智慧牧场</el-button>
					<el-button @click="handleChangeImgs('3')" type="primary">奶牛发情监测系统</el-button>
					<el-button @click="handleChangeImgs('4')" type="primary">饲喂管理系统</el-button>
					<el-button @click="handleChangeImgs('5')" type="primary">称重系统</el-button>
					<el-button @click="handleChangeImgs('6')" type="primary">环境控制系统</el-button>
					<el-button @click="handleChangeImgs('7')" type="primary">无线数据采集器</el-button>
					<el-button @click="handleChangeImgs('8')" type="primary">无线数据传输终端</el-button>
					<el-button @click="handleChangeImgs('9')" type="primary">LORA网关产品</el-button>
				</div>
				<div class="four_content_right">
					<div class="right_title">产品中心</div>
					<img style="width:80%;margin: 2% 0 0 10%" :src="imgs" alt="">
				</div>
			</div>
		</div>
		<div class="home_section_five">
			<img style="width:100%;height:100%" src="../../assets/t1.png" alt="">
		</div>
		<div class="home_section_six">
			<div style="display: flex;justify-content: space-between;align-items: center">
				<div>
					<h2>项目应用</h2>
					<h2>Project application</h2>
				</div>
				<el-dropdown split-button type="primary" size="medium" >
					犊牛饲喂管理系统
					<el-dropdown-menu slot="dropdown" >
						<el-dropdown-item>犊牛饲喂管理系统</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<div style="width:100%">
				<img style="width:100%" src="../../assets/1121.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
/**
 * author:duxp
 * 首页子组件
 */
export default {
	name: "HomeIndex",
	data(){
		return {
			images:[],
			information:'',
			imgs:require('../../assets/pictureimg.png')
		}
	},
	mounted() {
		this.getUserList();
		this.profile();
	},
	methods:{
		//跳转系统
		handleToSystem(index){
			if(index==0){
				window.open('https://calf.nxcyx.com/');
			}
		},
		//  请求后天轮播图1
		async getUserList() {
			let that=this;
			for(var i=0;i<3;i++){
				const { data: res } = await this.$http.get('/gw/news/getLbt?index='+(i+1), {
					params: this.res,
				})
				that.images.push(res.data.picPath)
			}
		},
		//  公司简介请求方法
		async profile() {
			var that =this;
			const {data: res}=await this.$http.get('/gw/news/getWzContentList?type=4&limit=1',{
				params: this.res,
			})
			that.information=res.data[0].content;
		},
		//产品中心图片切换方法
		handleChangeImgs(type){
			switch (type){
				case '1':
					this.imgs=require('../../assets/dnswxt.png')
					break;
				case '2':
					this.imgs=require('../../assets/zh.png')
					break;
				case '3':
					this.imgs=require('../../assets/fq.png')
					break;
				case '4':
					this.imgs=require('../../assets/swxt.png')
					break;
				case '5':
					this.imgs=require('../../assets/wight.png')
					break;
				case '6':
					this.imgs=require('../../assets/hg.png')
					break;
				case '7':
					this.imgs=require('../../assets/wxcj.png')
					break;
				case '8':
					this.imgs=require('../../assets/wxcs.png')
					break;
				case '9':
					this.imgs=require('../../assets/wg.png')
					break;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.home_index_wrap{
	width: 100%;
	.home_section_one{
		display: flex;
		justify-content: center;
		align-items: center;
		div{
			width: 15%;
			text-align: center;
			margin: 20px;
		}
		h3{
			font-size: 16px;
			color: #4b4f54;
		}
		p{
			font-size: 14px;
			color: #4b4f54;
		}
	}
	.home_section_two{
		width: 70%;
		margin: 0 auto;
		.section_two_content{
			display: flex;
			justify-content: center;
			align-items: center;
			.content_left{
				width: 60%;
				display: inline-block;
				padding-left: 2%;
				line-height:39px
			}
			.content_right{
				width: 40%;
				margin-left: 80px;
				img{
					width: 120%;
				}
			}
		}
	}
	.home_section_three{
		width: 100%;
		background-color: #0f91ee;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		padding: 20px 0;
		.three_item{
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 20%;
			color: #ffffff;
			span:nth-child(1){
				font-size: 28px;
				font-weight: bold;
			}
			span:nth-child(2){
				font-size: 16px;
			}
		}
	}
	.home_section_four{
		width: 70%;
		height: 820px;
		margin: 10px auto;
		.four_content{
			display: flex;
			justify-content: center;
			.four_content_left{
				width: 15%;
				display: flex;
				flex-direction: column;
			}
			.four_content_right{
				width: 75%;
				margin-left: 10%;
				.right_title{
					width: 100%;
					box-sizing: border-box;
					padding: 20px 0;
					text-align: center;
					background-color: #0f91ee;
					color: #ffffff;
					font-size: 22px;
				}
			}
		}
	}
	.home_section_five{
		width: 100%;
		height: 195px;
	}
	.home_section_six{
		width: 70%;
		margin: 10px auto;
	}
}
::v-deep .el-button{
	margin-left: 0px !important;
	margin-top: 2px !important;
}
</style>
