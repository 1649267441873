import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../components/home.vue'
// import About from '../components/about.vue'
// import News from '../components/news.vue'
// import Honor from '../components/honor.vue'
// import Product from '../components/product.vue'
// import Contact from '../components/contact.vue'
// import Details from '../components/details.vue'
import Index from "@/views/Index"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		redirect: '/index/home',
	},
	{
		path: '/index/',
		name: 'index',
		component: Index,
		children:[
			/**
			 * 首页
			 */
			{
				path: "home",
				name: "home",
				component: (resolve) => require(['@/views/components/HomeIndex.vue'], resolve),
			},
			/**
			 * 关于我们
			 */
			{
				path: "aboutUs",
				name: "aboutUs",
				component: (resolve) => require(['@/views/components/AboutUs.vue'], resolve),
			},
			/**
			 * 产品中心
			 */
			{
				path: "proCenter",
				name: "proCenter",
				component: (resolve) => require(['@/views/components/ProCenter.vue'], resolve),
			},
			/**
			 * 文档下载
			 */
			{
				path: "docDown",
				name: "docDown",
				component: (resolve) => require(['@/views/components/DocDown.vue'], resolve),
			},
			/**
			 * 新闻动态
			 */
			{
				path: "newsComp",
				name: "newsComp",
				component: (resolve) => require(['@/views/components/NewsComp.vue'], resolve),
			},
			/**
			 * 新闻动态详情
			 */
			{
				path: 'detailsComp',
				name: "detailsComp",
				component: (resolve) => require(['@/views/components/DetailsComp.vue'], resolve),
			},
			/**
			 * 联系我们
			 */
			{
				path: 'contactUs',
				name: "contactUs",
				component: (resolve) => require(['@/views/components/ContactUs.vue'], resolve),
			}
		]
	}
  // {
  //   path:'/home.vue',
  //   name:'home',
  //   component: Home
  // },
  // {
  //   path:'/about.vue',
  //   name:'about',
  //   component: About
  // },
  // {
  //   path:'/product.vue',
  //   name:'product',
  //   component: Product
  // },
  // {
  //   path:'/news.vue',
  //   name:'news',
  //   component: News
  // },
  // {
  //   path:'/honor.vue',
  //   name:'honor',
  //   component: Honor
  // },
  // {
  //   path:'/contact.vue',
  //   name:'contact',
  //   component: Contact
  // },
  // {
  //   path:'/details.vue',
  //   name:'details',
  //   component: Details
  // },
]

const router = new VueRouter({
  routes
})

export default router
